const DISCOUNT_TYPES = {
  percentage: 'percentage_discount',
  per_item: 'per_item_discount',
  per_total: 'per_total_discount',
  shipping: 'shipping_discount',
  free_shipping: 'free_shipping',
  promotion: 'promotion',
} as const;

const RECOMMENDED_PRODUCTS_DISCOUNT = {
  code: 'djur15',
  amount: 15,
  type: DISCOUNT_TYPES.percentage,
};

const HIDDEN_PROMO_CODES = [RECOMMENDED_PRODUCTS_DISCOUNT.code];

export { DISCOUNT_TYPES, HIDDEN_PROMO_CODES, RECOMMENDED_PRODUCTS_DISCOUNT };
