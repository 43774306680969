const CATEGORY_AND_BRAND_COMBINATION_PURCHASE_LIMIT = {
  sv: {
    dev: [
      {
        brandIds: [
          52, // EverClean
          105, // FourFriends, (PrimaCat does not exist in dev)
        ],

        categoryIds: [425], // Catsand,
        limit: 4,
      },
      {
        brandIds: [
          59, // Monster (MUSH does not exist in dev)
        ],

        categoryIds: [561], // Frozen food
        limit: 1,
      },
    ],
    prod: [
      {
        brandIds: [
          245, // PrimaCat
          51, // EverClean
          114, // FourFriends
          113, // Intersand
        ],

        categoryIds: [290, 291, 71], // Catsand
        limit: 4,
      },
    ],
  },
};

const SKUS_PURCHASE_LIMIT = {
  sv: {
    dev: [
      {
        skus: ['L11781', '12366972'], // Professional Mini för Hund, Medium Adult Sensitive Digestion Lamm 14kg

        limit: 2,
      },
      {
        skus: ['208193'], // Nutrisal Vätskeersättning För Hund Och Katt
        limit: 1,
      },
    ],
    prod: [
      {
        skus: [
          '513727',
          '513127',
          '513427',
          '514227',
          '513627',
          '514827',
          '513927',
          '513327',
          '513527',
          '513367',
          '513167',
          '513027',
          '513467',
          '513767',
        ],
        limit: 4,
      },
    ],
  },
};

export { CATEGORY_AND_BRAND_COMBINATION_PURCHASE_LIMIT, SKUS_PURCHASE_LIMIT };
